/**
 * Which color scheme to use for a UI component
 * - default: based on user darkmode preference
 * - invert: the opposite of the user's darkmode preference (light on dark and dark on light)
 * - light: always light theme
 * - dark: always dark theme
 */
export type ComponentTheme = "default" | "light" | "dark" | "invert";

const prefixDark = (c: string) => `dark:${c}`;

export type LightDarkClasses = {
  light: string[];
  dark: string[];
};

export type FullThemeClasses = {
  light: string[];
  dark: string[];
  default: string[];
  invert: string[];
};

/**
 * Transforms light and dark classes into a full set of themes.
 *
 * Best explained with an example:
 *
 * Imagine defining theme colors for a red buton
 * ```js
 * getThemeClasses({
 *  light: ["bg-red-200"], // light class variant is this class
 *  dark: ["bg-red-800"] // dark class variant is this class
 * })
 * // will return
 * {
 *    default: "bg-red-200 dark:bg-red-800", // default theme means light class in light mode, dark class in dark mode
 *    invert: "bg-red-800 dark:bg-red-200", // invert theme means dark on light and light on dark
 *    light: "bg-red-200", // light theme means always light
 *    dark: "bg-red-800" // dark theme means always dark
 * }
 * ```
 * # Warning
 * Calling this function will generate `dark:` prefixed classes dynamically  which is generally a [big no-no in tailwind](https://tailwindcss.com/docs/content-configuration#dynamic-class-names).
 *
 * To workaround the purging you must wrap the function call with:
 * ```
 * //#region theme
 * ...code here
 * //#endregion
 * ```
 *
 * Any tailwind classes in that region will be picked up and prefixed with their `dark:` variants and added to a `safelist` in the tailwind config file.
 *
 * This is accomplished using a custom vite plugin you can find in `apps/webconsole/vite-plugins/tailwind-safelist-themes.ts`
 */
export const getThemeClasses = ({
  light,
  dark,
}: LightDarkClasses): FullThemeClasses => ({
  light,
  dark,
  default: [...light, ...dark.map(prefixDark)],
  invert: [...dark, ...light.map(prefixDark)],
});
