import { DropdownMenu as dm } from "@kobalte/core";
import type { IconTypes } from "solid-icons";
import {
  type Component,
  For,
  Match,
  Show,
  Switch,
  type JSXElement,
  type ParentComponent,
  type Setter,
} from "solid-js";
import { twMerge } from "tailwind-merge";
import { StIcon } from "../icons";
import { HorizontalDivider } from "../dividers";
import { TbChevronRight } from "solid-icons/tb";
import { type ComponentTheme, getThemeClasses } from "~/lib/theme";

type Item = {
  kind: "item";
  content: JSXElement;
  props?: dm.DropdownMenuItemProps;
  icon?: IconTypes;
};
type Separator = {
  kind: "separator";
};
type SubMenu = {
  kind: "menu";
  trigger: Omit<Item, "kind">;
  items: StDropdownMenuItem[];
};
type Group = {
  kind: "group";
  label: string;
  items: StDropdownMenuItem[];
};
export type StDropdownMenuItem = Item | Separator | SubMenu | Group;

//#region theme
const menuTheme = getThemeClasses({
  light: ["bg-white", "border-slate-200"],
  dark: ["bg-indigo-1100", "border-indigo-900"],
});
//#endregion
export const StDropdown: ParentComponent<{
  /**
   * Which color scheme to use for the dropdown
   * - default: based on user darkmode preference
   * - invert: the opposite of the user's darkmode preference (light on dark and dark on light)
   * - light: always light theme
   * - dark: always dark theme
   */
  theme: ComponentTheme;
  opts?: dm.DropdownMenuRootProps;
  trigger?: dm.DropdownMenuTriggerProps;
  content?: dm.DropdownMenuContentProps;
  containerClass?: string;
  mount?: HTMLElement;
  items: StDropdownMenuItem[];
  setRef?: Setter<HTMLDivElement | undefined>;
}> = (props) => {
  return (
    <dm.Root {...props.opts}>
      <dm.Trigger {...props.trigger}>{props.children}</dm.Trigger>
      <dm.Portal mount={props.mount} ref={props.setRef}>
        <dm.Content
          {...props.content}
          class={twMerge(
            "min-w-56 p-2 rounded-md border shadow-lg origin-kb animate-kb-hide kb-expanded:animate-kb-show outline-none z-50",
            menuTheme[props.theme],
            props.content?.class,
          )}
        >
          <div class={props.containerClass}>
            <For each={props.items}>
              {(item) => {
                return (
                  <StDropdownItem
                    theme={props.theme}
                    item={item}
                    mount={props.mount}
                    content={props.content}
                  />
                );
              }}
            </For>
          </div>
          <dm.Arrow />
        </dm.Content>
      </dm.Portal>
    </dm.Root>
  );
};

const StDropdownItem: Component<{
  theme: ComponentTheme;
  item: StDropdownMenuItem;
  mount?: HTMLElement;
  content?: dm.DropdownMenuContentProps;
}> = (props) => {
  //#region theme
  const itemTheme = getThemeClasses({
    light: [
      "text-slate-900",
      "kb-expanded:bg-violet-200",
      "kb-highlighted:bg-violet-500",
      "kb-highlighted:text-white",
    ],
    dark: [
      "text-slate-100",
      "kb-expanded:bg-violet-900",
      "kb-highlighted:bg-violet-700",
      "kb-highlighted:text-white",
    ],
  });
  //#endregion

  return (
    <Switch>
      <Match when={props.item.kind === "item"}>
        <dm.Item
          {...(props.item as Item).props}
          class={twMerge(
            "flex items-center rounded-md h-8 px-7 relative select-none outline-none kb-disabled:opacity-50 kb-disabled:pointer-events-none",
            itemTheme[props.theme],
            (props.item as Item).icon && "pl-1",
            (props.item as Item).props?.class,
          )}
        >
          <Show when={(props.item as Item).icon}>
            {/* biome-ignore lint/style/noNonNullAssertion: <explanation> */}
            <StIcon icon={(props.item as Item).icon!} class="mr-2" />
          </Show>
          {(props.item as Item).content}
        </dm.Item>
      </Match>
      <Match when={props.item.kind === "separator"}>
        <HorizontalDivider class="w-full my-2" />
      </Match>
      <Match when={props.item.kind === "menu"}>
        <dm.Sub overlap shift={-8} gutter={12}>
          <dm.SubTrigger
            {...(props.item as SubMenu).trigger.props}
            class={twMerge(
              "flex items-center rounded-md h-8 pr-2 pl-7 relative select-none outline-none kb-disabled:opacity-50 kb-disabled:pointer-events-none",
              itemTheme[props.theme],
              (props.item as SubMenu).trigger.icon && "pl-1",
            )}
          >
            <Show when={(props.item as SubMenu).trigger.icon}>
              <StIcon
                /* biome-ignore lint/style/noNonNullAssertion: <explanation> */
                icon={(props.item as SubMenu).trigger.icon!}
                class="mr-2"
              />
            </Show>
            <div class="flex items-center flex-auto">
              {(props.item as SubMenu).trigger.content}
            </div>
            <StIcon icon={TbChevronRight} />
          </dm.SubTrigger>

          <dm.Portal mount={props.mount}>
            <dm.SubContent
              {...props.content}
              class={twMerge(
                props.content?.class,
                "min-w-56 p-2 rounded-md border shadow-lg origin-kb animate-kb-hide kb-expanded:animate-kb-show outline-none z-50",
                menuTheme[props.theme],
              )}
            >
              <For each={(props.item as SubMenu).items}>
                {(item) => (
                  <StDropdownItem
                    theme={props.theme}
                    item={item}
                    mount={props.mount}
                    content={props.content}
                  />
                )}
              </For>
            </dm.SubContent>
          </dm.Portal>
        </dm.Sub>
      </Match>
      <Match when={props.item.kind === "group"}>
        <dm.Group>
          <dm.GroupLabel class="px-6 text-sm leading-8 text-slate-500 select-none">
            {(props.item as Group).label}
          </dm.GroupLabel>

          <For each={(props.item as Group).items}>
            {(item) => (
              <StDropdownItem
                theme={props.theme}
                item={item}
                mount={props.mount}
                content={props.content}
              />
            )}
          </For>
        </dm.Group>
      </Match>
    </Switch>
  );
};
