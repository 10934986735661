import init from "typescript-plugin-css-modules";

/**
 * extractInitials Extracts the initials from a name. It takes the first and last initials if there's more than
 * one part. If there's only one part, it takes the first two initials. If there's no name, it returns '??'.
 *
 * @param name
 */
export const extractInitials = (name: string): string => {
  if (!name) {
    return "??";
  }

  const parts = name.trim().split(/\s+/).filter(Boolean);
  let initials = parts
    .slice(0, 2)
    .map((part) => (part[0] ? part[0].toUpperCase() : ""));

  // If there's more than one part, take the first and last part's initial
  if (initials.length > 1 && initials[0] && initials[initials.length - 1]) {
    initials = [initials[0], initials[initials.length - 1] ?? ""];
  }

  return initials.join("");
};
